<template>
    <transition name="modal">
        <div class="modal-mask" @click.self="closeModal">
            <div class="modal">
                <div class="modal__header">
                    <button aria-label="close-modal" type="button" @click="closeModal" class="button close-modal">
                        <svg width="64" height="62" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M4.88176 61.3705C16.014 54.9001 26.0386 44.4689 35.0116 35.2715C43.3327 26.7401 52.5441 16.8276 55.7969 5.13463C56.9255 1.06871 51.0299 -0.936205 48.6675 1.97303C40.9773 11.4508 35.6005 22.3938 27.3565 31.6332C19.2106 40.7535 8.8916 48.1562 1.34161 57.6971C-0.579187 60.1227 2.47025 62.7655 4.88176 61.3705V61.3705Z"
                                    fill="#355878"/>
                            <path
                                    d="M2.96107 7.63713C9.43149 18.7693 19.8627 28.7939 29.0601 37.767C37.5915 46.0881 47.5039 55.2995 59.1969 58.5522C63.2628 59.6809 65.2678 53.7853 62.3585 51.4229C52.8807 43.7327 41.9378 38.3558 32.6984 30.1118C23.5781 21.966 16.1753 11.647 6.63442 4.09698C4.20889 2.17618 1.56604 5.22562 2.96107 7.63713V7.63713Z"
                                    fill="#355878"/>
                        </svg>
                    </button>
                </div>
                <div class="modal__body">
                    <h2>Pour connaître la disponibilité des produits, dites-nous où souhaitez-vous être livré ?</h2>
                    <form class="location" @submit.prevent="">
                        <div class="form__row">
                            <AddressAutocomplete id="homeLocation"
                                                 placeholder="Indiquez votre adresse de livraison souhaitée"
                                                 classname="input"
                                                 :set-location="true"
                                                 @change="closeModal"
                                                 required>
                            </AddressAutocomplete>
                            <button aria-label="open" class="button">
                                <svg width="52" height="82" viewBox="0 0 52 82" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M1.84137 75.4239C11.4161 63.4726 24.1693 53.9096 34.931 42.8418C35.9512 41.7933 36.9617 40.7447 37.9427 39.6574C39.424 38.0166 40.8269 36.3467 42.1807 34.6283C43.2402 35.638 44.2997 36.5992 45.3592 37.5603C47.1839 39.1914 49.087 40.7059 50.9902 42.1719C50.1858 43.0068 49.3813 43.8515 48.5769 44.6476C46.713 46.5311 44.7607 48.5019 42.8183 50.4339C31.8898 61.4629 19.892 72.9093 6.75625 80.4626C3.41099 82.3946 -0.826992 78.7442 1.84137 75.4336V75.4239Z"
                                            fill="white"/>
                                    <path
                                            d="M9.17935 1.83266C21.2949 11.3083 30.9186 23.978 42.1905 34.6283C43.25 35.638 44.3095 36.5992 45.369 37.5603C47.1937 39.1914 49.0968 40.7059 51 42.1719C50.1956 43.0068 49.3911 43.8515 48.5867 44.6476C46.7228 46.5311 44.7706 48.5019 42.8281 50.4339C41.9845 49.6378 41.131 48.8417 40.3266 48.0456C38.5509 46.3272 36.7655 44.6088 34.9408 42.8418C23.6297 31.939 11.8477 19.8615 4.0977 6.69667C2.14548 3.38604 5.83409 -0.808081 9.17935 1.83266Z"
                                            fill="white"/>
                                    <path
                                            d="M1.84137 75.4239C11.4161 63.4726 24.1693 53.9096 34.931 42.8418C35.9512 41.7933 36.9617 40.7447 37.9427 39.6574C39.424 38.0166 40.8269 36.3467 42.1807 34.6283C43.2402 35.638 44.2997 36.5992 45.3592 37.5603C47.1839 39.1914 49.087 40.7059 50.9902 42.1719C50.1858 43.0068 49.3813 43.8515 48.5769 44.6476C46.713 46.5311 44.7607 48.5019 42.8183 50.4339C31.8898 61.4629 19.892 72.9093 6.75625 80.4626C3.41099 82.3946 -0.826992 78.7442 1.84137 75.4336V75.4239Z"
                                            stroke="white" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                    <path
                                            d="M9.17935 1.83266C21.2949 11.3083 30.9186 23.978 42.1905 34.6283C43.25 35.638 44.3095 36.5992 45.369 37.5603C47.1937 39.1914 49.0968 40.7059 51 42.1719C50.1956 43.0068 49.3911 43.8515 48.5867 44.6476C46.7228 46.5311 44.7706 48.5019 42.8281 50.4339C41.9845 49.6378 41.131 48.8417 40.3266 48.0456C38.5509 46.3272 36.7655 44.6088 34.9408 42.8418C23.6297 31.939 11.8477 19.8615 4.0977 6.69667C2.14548 3.38604 5.83409 -0.808081 9.17935 1.83266Z"
                                            stroke="white" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <template v-if="!isAuthenticated">
                        <p>Vous êtes déjà client ? </p>
                        <button aria-label="connection" @click="connexion()" class="button full" tabindex="0">
                            Connectez-vous
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {getAPI} from "@/services/axios-api";
import AddressAutocomplete from "@/components/AddressAutocomplete.vue";

export default {
    name: "LocationModal",
    components: {
        AddressAutocomplete
    },
    data() {
        return {
            location: {
                latitude: null,
                longitude: null,
                city: null,
                zip: null,
                country: null,
            },
        }
    },
    computed: {
        ...mapGetters('auth', ['getLocation', 'getIsAuthenticated']),
        ...mapState('auth', ['userId']),
        ...mapState('auth', ['userId', 'isAuthenticated']),
    },
    methods: {
        connexion: function () {
            this.$store.dispatch('changeStateLModal', false);
            this.$router.push({name: 'Registration'})
        },
        closeModal: function () {
            this.$store.dispatch('changeStateLModal', false);
            this.$store.dispatch('changeStateLoading', false);
            document.querySelector('html').style.overflow = null
            document.querySelector('body').style.overflow = null
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  @include flex(center, center);
  @include position(0, 0, $position: fixed);
  z-index: 997;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease;
  overflow: hidden;
}

.modal {
  @include flex(center, center, column, $gap: 10px);
  background-color: $White;
  border-radius: 15px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 10px 10px -3px rgba(0, 0, 0, 30%);
  padding: 20px;
  pointer-events: all;
  position: relative;
  z-index: 998;

  &__header {
    @include flex(flex-start, flex-start, $gap: 50px);
    width: 100%;

    button.close-modal {
      padding: 5px;
      aspect-ratio: 1;
      margin-left: auto;
      height: 35px;

      svg {
        fill: $Brown;
        @include fixed-size(20px)
      }
    }
  }

  &__body {
    @include flex(flex-end, center, column, $gap: 10px);
    width: 100%;

    h2 {
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      margin-bottom: 10px;
    }

    form {
      width: 100%;

      .form__row {
        @media screen and (max-width: 568px) {
          button {
            display: none;
          }
        }

        @include flex(center, center, row);
        width: 100%;
        text-align: center;

        @media screen and (min-width: 568px) {
          flex-direction: row;
        }

        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height: 35px;

          @media screen and (min-width: 568px) {
            border-right: none;
          }
        }

        button {
          padding: 5px;
          height: 35px;
          font-size: 16px;
          width: 100%;
          aspect-ratio: 1;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          @media screen and (min-width: 568px) {
            width: auto;
          }

          svg {
            @include fixed-size(20px)
          }
        }
      }
    }

    .button {
      width: 100%;
      padding: 5px 10px;
      @media screen and (min-width: 568px) {
        width: auto;
      }
    }
  }
}

</style>